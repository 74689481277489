import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import stringToSlug from '../../utils/slugify'
import { SITENAME } from '../../utils/siteConfig'
import LogoPrimary from '../../../public/svg/logo.svg'

import SocialIcons from '@big-d-companies/social-icons'
import FooterLogos from '@big-d-companies/footer-logos'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}
import {
  Paragraph,
  Container,
  ItemsRenderer,
  GridContainer,
} from '../index'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import Styles from './Footer.module.scss'

const Footer = ({ title, subTitle, globalMenus, greatPlaceToWorkLogoUrl, footerLogos }) => {
  const footer = globalMenus?.footer
  const socialItems = globalMenus?.socialNetworks?.menuItems?.nodes || []

  const footerLeftColItems = footer?.leftCol?.menuItems?.nodes
  const footerCentralColItems = footer?.centralCol?.menuItems?.nodes
  const footerRightColItems = footer?.rightCol?.menuItems?.nodes
  const footerbottomItems = footer?.bottom?.menuItems?.nodes
  // const hash = require('string-hash');

  const NavRenderer = ({ className, title, items, anchorClassName }) => {
    const slugifiedTitle = title ? stringToSlug(title) : ''
    return (
      <nav className={`pt-40 md:pt-0 flex flex-col col-span-4 md:col-span-1.5 ${className}`}>
        <div
          className={'pb-20 text-xs-B -tracking-3 uppercase md:text-base-A xl:pb-35 font-light'}
          id={`footer-navigation-items-heading-${slugifiedTitle}`}>
          {title}
        </div>

        <ItemsRenderer
          showActiveDash={false}
          isFooter={true}
          list={items}
          className='flex flex-col text-background text-sm-B -tracking-3 md:text-base md:space-y-10'
          anchorClassName='button-underline'
          ariaLabelledBy={`footer-navigation-items-heading-${slugifiedTitle}`}
        />
      </nav>
    )
  }

  const footerRef = useRef()
  const footerPRef = useRef()
  const tl = useRef()

  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 768px)': function () {
        tl.current = gsap
          .timeline({
            scrollTrigger: {
              trigger: footerRef?.current,
              scrub: true,
              invalidateOnRefresh: true,
              start: '0% 100%',
              end: '100% 100%',
            },
          })
          .addLabel('init')
          .fromTo(
            footerPRef?.current,
            {
              yPercent: -50,
            },
            {
              yPercent: 0,
            },
            'init'
          )

        return function () {
          tl?.current?.kill()
          gsap.set(footerPRef?.current, { clearProps: true })
        }
      },
    })
  }, [])

  return (
    <footer
      id='footer'
      ref={footerRef}
      className={clsx(
        Styles.footer,
        'flex flex-col justify-center text-secondary bg-white overflow-hidden'
      )}>
      <div ref={footerPRef} style={{ willChange: 'transform' }}>
        <h2 className='sr-only'>Footer</h2>
        <Container>
          {/* Top Half */}
          <GridContainer className='pb-60 pt-70 md:pb-80 md:pt-150'>
            <div className='hidden col-span-4 row-start-1 space-y-18 md:block md:col-span-1 md:col-start-1 md:row-start-auto'>
              <LogoPrimary className='w-full h-auto invert' />
            </div>
            {/* Tag Line - Fanatical Craftmansip */}
            <div className='col-span-4 row-start-1 space-y-18 md:col-span-2 md:col-start-2.5 md:row-start-auto'>
              <Paragraph
                text={title}
                overrideClassName='footer-title text-4xl-A -tracking-3 md:text-4xl-E font-light'
              />
              <div className='overflow-hidden'>
                <Paragraph
                  text={subTitle}
                  overrideClassName='text-background text-base -tracking-3 xl:pt-20 font-light'
                />
              </div>
            </div>

            {/* COMPANY */}
            <NavRenderer
              className='md:col-start-5'
              title={footer?.leftCol?.acf?.title}
              items={footerLeftColItems}
            />

            {/* INFORMATION */}
            <NavRenderer
              // className='col-span-4 md:col-span-1.5'
              title={footer?.centralCol?.acf?.title}
              items={footerCentralColItems}
            />

            {/* REACH OUT */}
            <NavRenderer
              // className='col-span-4 md:col-span-1.5'
              title={footer?.rightCol?.acf?.title}
              items={footerRightColItems}
            />

            {/* DATA - Show on Mobile only */}
            <NavRenderer className='hidden' title='Data' items={footerbottomItems} />

            <div
              className={`pt-40 md:pt-0 flex flex-col col-span-1 md:col-span-1 md:row-start-1 md:col-start-9`}>
              <Image
                src={greatPlaceToWorkLogoUrl}
                alt={'Great Place to Work'}
                height='150%'
                width='100%'
                objectFit='contain'
              />
            </div>

            <div className='col-span-1 col-start-8 justify-self-center md:col-start-9 md:row-start-2'>
              <SocialIcons
                socialItems={socialItems}
                classNames={{
                  link: clsx(
                    Styles.socialIconsLink,
                    'pointer rounded-full transition duration-100 ease-in-out',
                    'w-32 h-32 md:w-38 md:h-38',
                    'focus:outline-none focus-visible:outline-white'
                  ),
                }}
              />
            </div>
          </GridContainer>
        </Container>

        <div className='px-16 md:px-30'>
          <GridContainer
            className={clsx(
              'pb-28 pt-24 md:pb-35 md:pt-30',
              'border-t border-secondary',
              'flex items-center justify-center align-middle'
            )}>
            <div className='col-span-3'>
              <Paragraph
                text='The Big-D Family of Companies'
                overrideClassName='text-background text-2xl-A md:text-base-A -tracking-3 uppercase font-light'
              />
            </div>

            <div className='hidden md:block md:col-span-7 md:col-start-6.5 md:justify-self-end lg:col-span-4'>
              <h3 className='sr-only' id='footer-bottom-navigation'>
                Footer Bottom Navigation
              </h3>
              <ItemsRenderer
                isFooter={true}
                showActiveDash={false}
                list={footerbottomItems}
                className='flex text-xs-C uppercase space-x-40'
                anchorClassName='button-underline'
                ariaLabelledBy='footer-bottom-navigation'
              />
            </div>
          </GridContainer>
          <FooterLogos
            siteName={SITENAME}
            footerLogos={footerLogos}
            classNames={{
              headline: 'hidden',
              logosContainer: [
                'mb-70 mt-70 md:mt-20',
                'justify-between',
                'mx-auto max-w-220',
                'flex flex-wrap',
                'sm:gap-[1.8rem] sm:flex-row sm:justify-center sm:text-center sm:overflow-x-hidden sm:overflow-y-hidden',
              ],
              link: [
                'grow-0 flex flex-col items-center md:flex-1',
                'transform md:w-auto md:scale-90',
              ],
              title: [
                'text-center text-xs-A uppercase',
                'border-b border-primary-light',
                'pb-5 pt-30',
                'w-5/5 2xl:w-5/5 md:w-[110%] whitespace-nowrap',
              ]
            }}
          />
        </div>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
